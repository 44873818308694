<template>
  <div class="sp__wrapper">
    <!-- display landing page if timed assessment is not started -->
    <template v-if="getCourseViewSelector === 1">
      <LandingPage />
    </template>
    <!-- display completed layout page if timed assessment is submitted -->
    <template v-else-if="getCourseViewSelector === 3">
      <CompletedAssessment @onFinish="$emit('onFinish')" />
    </template>
    <!-- display pre requisite incomplete message -->
    <template v-else-if="getCourseViewSelector === 4">
      <Prerequisite />
    </template>
    <!-- display course unit layout -->
    <template v-else>
      <div
        :class="['sp__wrapper--shimmer', { sp_shimmer__scorm: isScormUnit }]"
        v-if="!iframeContentStatus"
      >
        <Shimmer />
      </div>
      <CourseUnit
        :style="iframeContentStatus ? 'opacity:1' : 'opacity:0'"
        :blockURL="blockURL"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    LandingPage: () => import("./TimedAssessment/LandingPage.vue"),
    CourseUnit: () => import("./CourseUnit.vue"),
    CompletedAssessment: () =>
      import("./TimedAssessment/CompletedAssessment.vue"),
    Shimmer: () => import("@/components/WrapperComponents/Shimmer.vue"),
    Prerequisite: () => import("./Prerequisite.vue")
  },
  data() {
    return {
      sortBy: "",
      filterBy: ""
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentUnitId",
      "getCourseViewSelector",
      "getSelectedPostResponses",
      "getCurrentMenuItem",
      "iframeContentStatus",
      "isScormUnit"
    ]),
    blockURL: {
      get() {
        // Sample URLs: retained here for reference
        // return `http://localhost:8073/xblock/block-v1:WHOA+CS101+2014_T1+type@vertical+block@16062946ac5a421e9b226bdf03ac3491`;
        // return `https://lms-staging.academy.who.int/xblock/${this.currentBlock.id}`
        //   : "";
        return this.getCurrentUnitId !== ""
          ? `/lms-xblock/${this.getCurrentUnitId}`
          : "";
      }
    }
  }
};
</script>
<style lang="scss">
.sp__wrapper {
  height: 100%;
  .sp__wrapper--shimmer {
    padding: 0 16px;
    &.sp_shimmer__scorm {
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      border-radius: 16px;
      padding: 24px 16px;
      background: $brand-neutral-0;
      margin: 28px 80px 0;
      height: 670px;
      .shimmer-line {
        margin-top: 15px;
      }
    }
  }
}
</style>
